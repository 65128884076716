@import "../variables";
@import "bootstrap/scss/mixins/breakpoints";

.storepage {
    width: 100%;
}
.homepage-banner {
    display: flex;
    position: relative;
    height: 452px;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
    background: #0171CF;
    img {
        display: flex;
        width: 100%;
    }
    .images {
        display: block;
        width: 100%;
    }
    .banner-image-left {
        display: none;
        left: 0;
        border-radius: 16px 0 0 16px;
    }
    .banner-image-right {
        display: none;
        right: 0;
        border-radius: 0 16px 16px 0;
    }
    .banner-image-mobile {
        right: 0;
        border-radius: 16px 16px 0 0;
    }
    .banner-body {
        color: $white;
        font-family: $main-font-family;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 56%;
        margin: auto;
        .banner-background {
            display: none;
            position: absolute;
            background: $primary;

            width: 100%;
            height: 100%;
        }
        .banner-content {
            position: absolute;
            background: $primary;
            display: flex;
            padding: 1rem;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            height: 100%;
            width: 100%;
            h3 {
                font-size: 24px;
                font-weight: 400;
                letter-spacing: 0;
                line-height: 32px;
                text-align: left;
                margin-top: 0.5rem;
            }
            .description {
                font-size: 14px;
                letter-spacing: 0.4px;
                line-height: 20px;
                text-align: left;
            }
            p {
                margin: 0;
            }
            a {
                border-radius: 32px;
                padding: 9px 24px;
                font-family: $main-font-family;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0.5px;
                line-height: 1.2;
                text-align: center;
                width: 100%;
                background-color: $light;
                color: $primary;
                margin: 1.5rem 0 0.5rem 0;
                text-decoration: none;
                white-space: nowrap;
                text-transform: uppercase;
                cursor: pointer;
                border: 1px solid $light;
                &:hover {
                    color: $light;
                    background-color: $primary;
                }
            }

            .info {
                font-size: 10px;
                letter-spacing: 0.4px;
                line-height: 16px;
                text-align: left;
            }
        }
    }
    @include media-breakpoint-up(md) {
        height: 360px;
        .images {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
        img {
            width: auto;
            height: 100%;
        }
        .banner-image-left {
            display: block;
        }
        .banner-image-right {
            display: block;
        }
        .banner-image-mobile {
            display: none;
        }
        .banner-body {
            width: 51%;
            height: 100%;
            .banner-background {
                display: block;
                transform: skewX(340deg);
            }
            .banner-content {
                background: transparent;
                align-items: center;
                justify-content: center;
                margin-left: 0.5rem;
                h3 {
                    font-size: 32px;
                    line-height: 40px;
                    text-align: center;
                    margin-top: 2rem;
                }
                .description {
                    font-size: 20px;
                    letter-spacing: 0;
                    line-height: 28px;
                    text-align: center;
                }
                a {
                    line-height: 2;
                    width: auto;
                    margin: 2rem;
                    cursor: pointer;
                     margin: 1.5rem 0;
                    &:hover {
                        color: $light;
                        background-color: $primary;
                    }
                }
                .info {
                    font-size: 10px;
                    letter-spacing: 0.4px;
                    line-height: 20px;
                    text-align: center;
                    width: 80%;
                }
            }
        }
    }
}
